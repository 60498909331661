.dash-widget-icon {
	align-items: center;
	border-radius: 10px;
	color: #fff;
	display: inline-flex;
	font-size: 1.875rem;
	height: 4rem;
	justify-content: center;
	text-align: center;
	width: 4rem;
}
.dash-widget-info {
	h3 {
		margin-bottom: 10px;
	}
}
.dash-widget-header {
	@extend %display-flex-center;
	@include margin-padding(0 0 20px 0, null);
}
.activity-feed {
	list-style: none;
	margin-bottom: 0;
	margin-left: 5px;
	padding: 0;
	.feed-item {
		border-left: 2px solid #e4e8eb;
		padding-bottom: 19px;
		padding-left: 20px;
		position: relative;
		&:last-child {
			border-color: transparent;
		}
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 1px;
			padding: 6px;
			left: -9px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: 2px solid $__smoke__white;
			background-color: transparent;
		}
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: -10px;
			width: 18px;
			height: 18px;
			border-radius: 50%;
    		border: 1px solid $__light__gray__has;
			background: $__light__gray__has;
		}
		.feed-date {
			display: block;
			position: relative;
			color: #777;
			text-transform: uppercase;
			font-size: 13px;
		}
		.feed-text {
			color: #777;
			position: relative;
			@include margin-padding(0 0 5px, null);
			a {
				color: #333;
				font-weight: 600;
			}
		}
	}
}
.modal-icon {
	i {
		font-size: 42px;
		color: #6c757d;
	}
}
.modal-footer {
	border: 0;
	@include margin-padding(null, 0 24px 24px);
	.btn-back {
		background: $__white;
		border: 1px solid $__light__gray;
		box-shadow: inset 0 0 0 0 $__grayish__blue;
		padding:8px 20px;
		@include rounded(6px);
		color: $__grayish__blue;
		min-width: 69px;
		&:hover {
			background: $__grayish__blue;
			border: 1px solid $__grayish__blue;
			box-shadow: inset 0 0 0 0 $__white;
			color: $__white;
		}
	}
	.btn-primary {
		min-width: 69px;
		@include margin-padding(0, null)
	}
}
.add-remove {
	i {
		font-size: 18px;
		cursor: pointer;
	}
}
.profile-cover {
	position: relative;
	padding: 1.75rem 2rem;
	border-radius: .75rem;
	height: 10rem;
}
.profile-cover-wrap {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 10rem;
	background-color: #e7eaf3;
	border-radius: .75rem;
}
.profile-cover-img {
	width: 100%;
	height: 10rem;
	-o-object-fit: cover;
	object-fit: cover;
	vertical-align: top;
	border-radius: .75rem;
}
.cover-content {
	position: relative;
	z-index: 1;
	padding: 1rem 2rem;
	position: absolute;
	bottom: 0;
	right: 0;
}
.custom-file-btn {
	position: relative;
	overflow: hidden;
}
.custom-file-btn-input {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	opacity: 0;
}
.profile-cover-avatar {
	display: -ms-flexbox;
	display: flex;
	margin: -6.3rem auto .5rem auto;
	border: 3px solid #fff;
	border-radius: 50%;
	@include respond-below(sm) {
		margin: 0 auto ;
	}
	input[type="file"] {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: -1;
		opacity: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(19,33,68,.25);
		border-radius: 50%;
		transition: .2s;
	}
}
.avatar-edit {
	position: absolute;
	bottom: 0;
	right: 0;
	cursor: pointer;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	color: #677788;
	background-color: #fff;
	border-radius: 50%;
	transition: .2s;
	box-shadow: 0 3px 6px 0 rgba(140,152,164,.25);
	svg {
		width: 18px;
	}
}
.error-page {
	align-items: center;
    color: #1f1f1f;
    @extend %display-flex-between;
    height: 100vh;
	.main-wrapper {
		display: flex;
		flex-wrap: wrap;
		height: auto;
		justify-content: center;
		width: 100%;
		min-height: unset;
	}
}
.error-box {
	margin: 0 auto;
	max-width: 480px;
	padding: 1.875rem 0;
	text-align: center;
	width: 100%;
	h1 {
		color: #7638ff;
		font-size: 10em;
	}
	p {
		margin-bottom: 1.875rem;
	}
	.btn {
		border-radius: 50px;
		font-size: 18px;
		font-weight: 600;
		min-width: 200px;
		padding: 10px 20px;
	}
}
