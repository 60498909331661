// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$template-primary: mat.define-palette(mat.$indigo-palette);
$template-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$template-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$template-theme: mat.define-light-theme((color: (primary: $template-primary,
        accent: $template-accent,
        warn: $template-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($template-theme);


// regular style toast
@import "node_modules/ngx-toastr/toastr";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import "node_modules/bootstrap/scss/bootstrap.scss";

@import "./assets/scss/main.scss";

.siderbar-view.show-sidebar {
  right: 600px;
  transition: ease all 0.8s;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
  z-index: 99999;
}

.icons-items {
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

.icons-list {
  max-height: calc(100vh - 328px);
}

// ::-webkit-scrollbar {
//   width: 4px;
//   height: 4px;
//   overflow-y: scroll;
//   background: #ffff;
//   box-shadow: inset 0 0 4px #707070;
// }

// ::-webkit-scrollbar-thumb {
//   background: #adb5bd;
//   border-radius: 0px;
// }

.tag_input {
  mat-form-field {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 5px;

    .mat-form-field-flex {
      padding: 0.4em 0.75em 0.4em 0.75em;
    }

    mat-chip-list {
      mat-chip {
        background-color: #7638ff !important;
        color: #fff !important;
        border-radius: 5px !important;

        mat-icon {
          color: white;
        }
      }
    }
  }
}

.show-sub-menu {
  display: block !important;
  transition: ease all 0.8s;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
}

.hide-sub-menu {
  display: none !important;
  transition: ease all 0.8s;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
}

.switch {
  .active {
    +.slider {
      background-color: $__primarycolor;
      border: 2px solid $__primarycolor;

      &:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(16px);
        background: $__white;
      }
    }
  }
}

// .feather {
//   font-size: 18px;
// }

.mini-sidebar .menu-title {
  display: none !important;
}

.table_header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top:10px;
  // padding: 1rem 1.5rem;

  .dataTables_length {
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    select {
      width: auto;
      display: inline-block;
      margin: 0px 3px;
    }
  }

  .dataTables_filter {
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    input {
      width: auto;
      display: inline-block;
      margin: 0px 3px;
      border: 1px solid #e5e5e5;
      color: #1b2559;
      height: 40px;
      border-radius: 8px;
      background: url(assets/img/icons/search.svg) no-repeat 5% 50% / 14px;
      padding-left: 30px;
    }
  }
}

.table_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 1.5rem 1.5rem;

  .pagination_section {
    display: flex;
    justify-content: flex-end;
  }
}

.edit-options .feather {
  font-size: 14px;
}

.list-inline-item span {
  margin: 0 5px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
 
  border-color: transparent;
  color: #333;
}

// .nav-tabs .nav-link.active {
//   color:#fff;
//   background-color: #001621;
//   border-color: #182b3e #182b3e #182b3e;
//   border: 1px solid #203247 !important;
// }

.mat-button-base {
  background: $__primarycolor;
  color: #fff;
}
.page-wrapper.page-wrapper-fluid {
  margin: 0 auto;
  padding: 0;
}

.page-wrapper.page-wrapper-fluid .page-header {
  margin: 0;
}

.page-wrapper.page-wrapper-fluid .content {
  padding: 0;
  max-width: 1140px;
}
.sidebar-three {
  .sidebar-menu-three {
    .tab-content-three {
      a {
        span {
          margin: 0 5px;
        }
      }
    }
  }
}

.tab {
  .tablinks {
    display: block;
    background-color: inherit;
    color: #6e82a5;
    padding: 22px 24px !important;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin: 0px 0px;
    font-size: 17px;
    border: 0 !important;
    transition: all 0.2s ease;
  }

}

.submenu-five {
  .dropdown-toggle::after {
    border-top: 0;
  }
}

.mini-sidebar {
  .header {
    .header-left.header-left-two {
      .logo.logo-small {
        padding: 10px 0 0;
        margin: 0;
      }
    }
  }
}

.page-wrapper.page-wrapper-fluid {
  @include respond-below(custom991) {
      padding: 15px;
  }
}
apx-chart {
  display: block;
  width: -webkit-fill-available !important;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 10000;
}
.date-mat-datepicker1-toggle{
  position: absolute;
  top:22px;
  right:9px;
}
.date-mat-datepicker2-toggle{
  position: absolute;
  top:24px;
  right:10px;
}
.pagination .page-item a{
  display: flex;
  align-items: center;
  font-size:12px;
  background: #fff;
}
.invoice-content-title a {
  font-size: 13px;
  padding: 10px;
  color: #28084B;
  display: inline-block;
}
.form-select .option{
  background-color: #7638ff !important;
}
.invoice-card-title h6 {
  margin-bottom: 24px;
  font-weight: 600;
}
.view-content {
  overflow: hidden;
  max-height: 200px;
  transition: max-height 0.5s ease-out;
}

.open {
  max-height: 2000px;
  display:block;
  
}

.closed {
  max-height: 200px;
  display:none;
  
}
.inv-content .invoice-address .company-details .company-name {
  font-size: 24px;
  color: #7638FF;
  margin: 0 0 10px;
  font-weight: 700;
  display: block;
}
.pagination{
  margin: 2px 0;
  white-space: nowrap;
  justify-content: space-between;
}
// upload image //
.service-upload {
  margin-right: 10px;
  border: 2px dashed $__light__whites;
  text-align: $__center;
  background-color: $__white;
  position: $__relative;
  @include margin-padding(null, 16px 0);
  min-height: 180px;
  border: 2px dashed #E5E5E5 !important;
  .drop-browse {
      font-size: $__font__size__14;
      font-weight: $__medium;
      color: $__grayish__blue;
      margin-bottom: 5px;
      flex-direction: $__column;
      p {
          font-weight: 500;
          font-size: 14px;
          color: $__light__vampire !important;
      }
  }
  span {
      font-size: 15px;
      color: #858585;
      display: $__block;
  }
  input[type="file"] {
      @include position($__absolute,0,null,null,0);
      width:$__full__width;
      height: $__full__height;
      opacity: 0;
      cursor: $__pointer;
  }
}
.service-upload.add-credit{
  min-height: 100%;
  height:100%;
}

.mdc-tooltip--shown .mdc-tooltip__surface-animation {
    background: #000;
    color: #fff;
    
}
.mdc-tooltip.mdc-tooltip--shown.mat-mdc-tooltip.mat-mdc-tooltip-show::after {
    content: "";
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 7px solid #000;
}
.btn-white:hover {
  background-color: #7539FF;
  border-color: #7539FF;
  color: #fff;
  box-shadow: inset 0 50px 0 0 #7539FF;
}
 .btn-white:focus:not(:focus-visible) {
  outline: 0;
  background-color: #fff;
  border-color: #e7eaf3;
  color: #7539FF;
}
.formcal{
  position:relative;
}
.mat-cust-select.form-control:focus {
  border-color: $__white;
}
 .sort-by .mat-cust-select.form-control {
   border: 0;
 }
.cancel:hover {
  background-color: #fff;
  border-color: #377dff;
  color: #fff; 
  box-shadow: inset 0 50px 0 0 #377dff;
}
button:focus:not(:focus-visible){
  color:#377dff;
}
.invoice-id{
  margin-bottom:0px;
}
.fc .fc-toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;
}
.bs-datepicker-body table td.week span 
{
  color: $__primarycolor !important; 
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker-head, .bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover 
{
  background-color: $__primarycolor !important; 
}

.table-scroll {
  overflow-x: auto;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.table-scroll::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  overflow-y: scroll;
  transition: all 0.5s;
  border: 4px solid transparent;
  background: transparent;
}
.table-scroll::-webkit-scrollbar-thumb {
  
  background: rgb(205, 202, 202);
  background-clip: content-box;
  border-radius: 50px;
  -webkit-background-clip: content-box;
  border:2px solid transparent;
}
.table-scroll::-webkit-scrollbar-thumb:hover {
  background: grey;
  background-clip: border-box;
  -webkit-background-clip: border-box;
  height: 15px;
  border: 8px solid grey;
}
.table-scroll {
 
  overflow-y: auto;
}
.card-table.permission{
  margin-bottom:24px;
}

.mat-mdc-tab-body-content{
  overflow: hidden !important;
}





 


 














